<template>
  <v-btn
    class="ml-2"
    color="info"
    href="https://dl.equinox.io/ngrok/ngrok-link/stable"
    target="_blank"
  >
    Download NGROK
    <v-divider
      class="mx-6"
      vertical
    />
    <v-icon>mdi-cloud-download-outline</v-icon>
  </v-btn>
</template>

<script>
  export default {
    name: 'FileNgrok',
  }
</script>
